import React from "react"
import tw, { styled } from "twin.macro"
import { SectionHeading } from "./misc/Headings.jsx"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Container = tw.div`relative mt-24 -mx-8 `
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto mb-8 md:my-16`
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`
const ImageColumn = tw(Column)`md:w-5/12 mt-6 flex-shrink-0 h-80 md:h-auto`
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
])

const TextContent = tw.div` text-center md:text-left`

const Heading = tw(
  SectionHeading
)`font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`
const Description = tw.p`mt-6 mb-6 mx-8 lg:mx-0 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const ButtonsContainer = tw.div`flex flex-col lg:flex-row `
const Button = tw(
  Link
)`mb-8  mx-2 text-center text-sm rounded-full px-5 py-3 font-bold  bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`

export default ({
  heading = <>DX推進をご検討中の方、お悩み・ご相談、何でもお答えします！</>,
  description = "ご相談方法は、資料請求、対面/オンライン形式でのお打合せ、メールでの質疑応答など、ご指定の方法で承ります。事例について詳細を知りたいといった方もまずはお気軽にお問合せ下さい。",
  textOnLeft = true,
}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <ButtonsContainer>
              <Button to="/contact/">資料請求、お問い合わせはこちら</Button>
              <Button to="/consultai/">AI無料相談会実施中！</Button>
              <Button to="/consultcloud/">AWS無料相談会実施中！</Button>
            </ButtonsContainer>
          </TextContent>
        </TextColumn>
        <ImageColumn>
          <StaticImage src="../images/document.png" />
        </ImageColumn>
      </TwoColumn>
    </Container>
  )
}
